<template>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy4" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="sizeMinAllView">
    <publish-button />
    <img
      class="hidden md:flex object-cover min-h-contain-passwordrecovery w-full"
      :src="loadImg(record.img_head_url)"
      alt=""
    />
    <div class="container-bg-passwordrecovery-desktop">
      <div
        class="bg-agrogo-green-2 bg-agrogo-green-2-transparent sizeMinAllView"
      >
        <div class="container pt-4 md:pt-20">
          <div class="grid grid-cols-1" v-if="!isVisibled && !isVisibled2">
            <div class="text-left">
              <p
                class="text-base text-white font-nexa font-normal ml-16 mr-10 mb-4 md:text-2xl lg:text-3xl md:ml-16 md:mr-16 md:mb-16"
              >
                {{
                  telephone
                    ? "Cambiar contraseña"
                    : "Recuperación de contraseña"
                }}
              </p>
              <form-password-recovery
                :isBusy="isBusy"
                :telephone="telephone"
                @send="send"
              />
              <div class="mx-8" v-if="errors.length > 0">
                <div class="h-0.5 bg-agrogo-yellow-1"></div>
                <div
                  class="flex items-center justify-center bg-agrogo-green-5 py-2.5 px-3 mb-4 md:min-h-94 md:mb-12"
                >
                  <p
                    v-for="(item, index) in errors"
                    :key="index"
                    class="text-xs font-dinpro font-normal text-agrogo-gray-1 md:text-xl"
                  >
                    {{ item.name }}
                  </p>
                  <!-- <p
                    class="text-xs font-dinpro font-normal text-agrogo-gray-1 md:text-xl"
                  >
                    Tu código ha sido enviado a
                    <span
                      class="icon-whatsapp font-dinpro font-normal text-white"
                    >
                      <span class="font-dinpro font-medium text-white">
                        Whatsapp</span
                      ></span
                    >
                  </p> -->
                </div>
              </div>
            </div>
          </div>
          <form-code
            v-else-if="isVisibled && !isVisibled2"
            :isBusy="isBusy2"
            :errors="errors2"
            :codeReset="codeReset"
            @validateCode="validateCode"
            @resendCode="resendCode"
          />
          <form-change-password
            v-else
            :isBusy="isBusy3"
            :errors="errors3"
            @changePassword="changePassword"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
// Mixins
import alertMessageMixin from "@/mixins/alertMessageMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
// components
import PxHeader from "@/components/header/PxHeader";
import PublishButton from "@/components/publishButton/PublishButton";
import FormPasswordRecovery from "./components/FormPasswordRecovery";
import FormCode from "./components/FormCode";
import FormChangePassword from "./components/FormChangePassword";

export default {
  inject: ["provider"],
  mixins: [loginRegisterMixin, alertMessageMixin],
  data() {
    const me = this;
    return {
      repository: me.provider.authRepository,
      publishFarmRepository: this.provider.publishFarmRepository,
      telephone: me.$route.query.telephone ? me.$route.query.telephone : "",
      isBusy: false,
      isBusy2: false,
      isBusy3: false,
      isBusy4: true,
      isVisibled: false,
      isVisibled2: false,
      errors: [],
      errors2: [],
      errors3: [],
      codesWp: [],
      code_by_wpp: null,
      formData: null,
      record: null,
      codeReset: false,
    };
  },
  components: {
    PxHeader,
    PublishButton,
    FormPasswordRecovery,
    FormCode,
    FormChangePassword,
  },
  methods: {
    async send(formData) {
      const me = this;
      try {
        me.isBusy = true;
        me.formData = formData;
        const dataAditional = await generateHash();
        await me.repository.passwordRecovery({
          ...dataAditional,
          ...formData,
        });
        const message =
          "Se ha enviado un código de verificación a tu Whatsapp.";
        me.resendCode(message);
        me.isVisibled = true;
      } catch (error) {
        me.errors = [];
        const { message } = error.response.data;
        me.errors.push({
          name: message,
        });
      } finally {
        me.isBusy = false;
      }
    },
    async validateCode(code) {
      const me = this;
      try {
        me.isBusy2 = true;
        me.code_by_wpp = code;
        const dataAditional = await generateHash();
        await me.repository.validateCode({
          ...dataAditional,
          ...me.formData,
          code_by_wpp: me.code_by_wpp,
        });
        me.isVisibled2 = true;
      } catch (error) {
        const code = error.response.status;
        me.errors2 = [];
        if (code == 403) {
          me.codeReset = true;
          me.errors2.push({
            name: "Código invalido.",
          });
        } else if (code == 401) {
          const message =
            "El código ha vencido, por favor solicite un nuevo código de verificación.";
          me.resendCode(message);
        } else {
          const { response } = error.response.data;
          me.errors2.push({
            name: response,
          });
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    async changePassword(formData) {
      const me = this;
      try {
        me.isBusy3 = true;
        const dataAditional = await generateHash();

        await me.repository.changePassword({
          ...dataAditional,
          ...me.formData,
          ...formData,
          code_by_wpp: me.code_by_wpp,
          token: dataAditional.apiKey,
        });

        me.$swal({
          iconHtml:
            '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0005 6.66797L48.7555 13.0546L59.5938 13.0346L62.9222 23.348L71.7022 29.7013L68.3338 40.0013L71.7022 50.3013L62.9222 56.6546L59.5938 66.968L48.7555 66.948L40.0005 73.3346L31.2455 66.948L20.4072 66.968L17.0788 56.6546L8.29883 50.3013L11.6672 40.0013L8.29883 29.7013L17.0788 23.348L20.4072 13.0346L31.2455 13.0546L40.0005 6.66797Z" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M28.334 40.0013L36.6673 48.3346L53.334 31.668" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          customClass: {
            icon: "no-border-swal-alert",
          },
          text: "Su contraseña ha sido actualizada",
          showConfirmButton: true,
          timer: 8000,
        });
        me.telephone ? me.$router.go(-1) : me.$router.push("/inicio-de-sesion");
      } catch (error) {
        me.errors3 = [];
        const { message } = error.response.data;
        me.errors3.push({
          name: message,
        });
      } finally {
        me.isBusy3 = false;
      }
    },
    resendCode(message) {
      const me = this;
      me.alertMenssage(message, 3000);
      me.isVisibled = false;
    },
    format(type, number) {
      const me = this;

      let attribute = null;
      let code = number + "";

      if (code) {
        let t = code.toString();
        let regex = /\d{1}/;
        attribute = t.match(regex)[0];
      } else {
        attribute = code;
      }

      if (type == "1") {
        me.formData.code1 = attribute;
      } else if (type == "2") {
        me.formData.code2 = attribute;
      } else if (type == "3") {
        me.formData.code3 = attribute;
      } else {
        me.formData.code4 = attribute;
      }
    },
    async loadData() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.publishFarmRepository.getAllNames(
          "PASSWORD_RECOVERY",
          dataAditional
        );
        me.record = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy4 = false;
      }
    },
    loadImg(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajeaboutusdesktop.jpg");
      return img;
    },
  },
  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadData();
  },
};
</script>
