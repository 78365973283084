<template>
  <div class="container px-16 pt-5">
    <div class="grid grid-cols-1">
      <div class="text-left md:mb-14">
        <p
          class="text-base text-white font-nexa font-normal mb-1 leading-9 md:text-2xl lg:text-3xl md:mb-9 md:px-6 md:mt-2"
        >
          Escribe tu nueva contraseña
        </p>
        <label
          for="password"
          class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base md:mb-2 md:ml-6"
          >Nueva Contraseña</label
        >
        <div class="relative md:mb-6">
          <div
            class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
          >
            <span class="icon-password text-agrogo-black-1 text-base"></span>
          </div>
          <input
            v-model="v$.formData.password.$model"
            @blur="v$.formData.password.$touch()"
            id="password"
            name="password"
            type="password"
            class="input-login"
          />
        </div>
        <p
          class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-2 text-left mt-1"
          v-if="v$.formData.password.$error"
        >
          {{ messageValidation(v$.formData.password) }}
        </p>
        <p
          class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
          v-else
        >
          &nbsp;
        </p>
        <label
          for="password"
          class="text-xs text-white font-nexa block ml-2 mb-1 text-left md:text-base md:mb-2 md:ml-6"
          >Confirmar contraseña</label
        >
        <div class="relative">
          <div
            class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
          >
            <span class="icon-password_2 text-agrogo-black-1 text-lg"></span>
          </div>
          <input
            v-model="v$.formData.password_confirmation.$model"
            @blur="v$.formData.password_confirmation.$touch()"
            id="password_confirmation"
            name="password_confirmation"
            type="password"
            class="input-login"
          />
        </div>
        <p
          class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-4 text-left mt-1"
          v-if="v$.formData.password_confirmation.$error"
        >
          {{ messageValidation(v$.formData.password_confirmation) }}
        </p>
        <p
          class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
          v-else
        >
          &nbsp;
        </p>
      </div>
      <div class="mb-8 md:mb-10">
        <button
          type="button"
          class="btn-login flex justify-center items-center rounded-lg"
          :disabled="isBusy || v$.$invalid"
          @click.prevent="changePassword"
        >
          <div class="loader" v-if="isBusy" />
          <span v-else> Continuar </span>
        </button>
      </div>
      <div
        class="mt-4 text-left bg-agrogo-yellow-1 p-2"
        v-if="errors.length > 0"
      >
        <ul class="ul">
          <li
            class="text-agrogo-green-2 font-nexa text-sm mb-1"
            v-for="(item, index) in errors"
            :key="index"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import { required, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  mixins: [validationMixin],
  emits: ["changePassword"],
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      formData: {
        password: "",
        password_confirmation: "",
      },
    };
  },
  validations() {
    return {
      formData: {
        password: {
          required,
        },
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.formData.password),
        },
      },
    };
  },
  methods: {
    changePassword() {
      const me = this;
      me.$emit("changePassword", me.formData);
    },
  },
};
</script>
